import request from '@/utils/request';

//获取问题话术列表
export function getLanguageList(data) {
  return request({
    url: '/clientserver.php/Language/index',
    method: 'post',
    data: data
  });
}

//新增话术
export function addLanguage(data) {
  return request({
    url: '/clientserver.php/Language/add',
    method: 'post',
    data: data
  });
}

//读取一条问题话术
export function popSpeechReference(data) {
  return request({
    url: '/clientserver.php/Language/read',
    method: 'post',
    data: data
  });
}

//删除
export function delLanguage(data) {
  return request({
    url: '/clientserver.php/Language/delete',
    method: 'post',
    data: data
  });
}
export function MyCustomerTop(data) {
  return request({
    url: '/clientserver.php/Language/top',
    method: 'post',
    data: data
  });
}

//点赞
export function thumbsUp(data) {
  return request({
    url: '/clientserver.php/Language/helpful',
    method: 'post',
    data: data
  });
}

//编辑
export function editLanguage(data) {
  return request({
    url: '/clientserver.php/Language/update',
    method: 'post',
    data: data
  });
}

//删除指定参考话术
export function editTechnique(data) {
  return request({
    url: '/clientserver.php/Language/deleteLanguage',
    method: 'post',
    data: data
  });
}
//上传音频
export function AliyunOssSign(data, progress) {
  return request({
    url: '/clientserver.php/AliyunOss/sign',
    method: 'post',
    data: data
  });
}
//阿里云回调
export function AliyunOssCallback(data) {
  return request({
    url: '/clientserver.php/AliyunOss/callback',
    method: 'post',
    data: data
  });
}
//展业城市列表
export function getPromoteCityList() {
  return request({
    url: '/clientserver.php/Common/get_promote_city_list',
    method: 'post'
  });
}
//展业城市承接项目
export function getProjectTypesByCityId(data) {
  return request({
    url: '/clientserver.php/Common/get_project_types_by_cityid',
    method: 'post',
    data: data
  });
}
//下载pdf
export function down(data) {
  return request({
    url: '/clientserver.php/Common/down',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}