var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "privateSea" },
    [
      _c("span", { staticClass: "nameTitle" }, [_vm._v("学员级别：")]),
      _vm._v(" "),
      _c("el-cascader", {
        attrs: {
          options: _vm.StudentLevel,
          size: "small",
          clearable: "",
          "collapse-tags": "",
          props: { multiple: true },
        },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.Student,
          callback: function ($$v) {
            _vm.Student = $$v
          },
          expression: "Student",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }