var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container language" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto", "margin-bottom": "20px" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "32px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c("el-row", { staticClass: "btn", attrs: { gutter: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "source" },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            padding: "20px 0 0",
                            "margin-right": "30px",
                          },
                          attrs: { span: 3.5 },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: { placeholder: "问题描述", size: "small" },
                              on: { change: _vm.ProblemDescriptionInput },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.ProblemDescriptionInput($event)
                                },
                              },
                              model: {
                                value: _vm.ProblemDescription,
                                callback: function ($$v) {
                                  _vm.ProblemDescription = $$v
                                },
                                expression: "ProblemDescription",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.ProblemDescriptionInput },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            padding: "20px 0 0",
                            "margin-right": "30px",
                          },
                          attrs: { span: 3.5 },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: { placeholder: "话术参考", size: "small" },
                              on: { change: _vm.WordsReferenceInput },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.WordsReferenceInput($event)
                                },
                              },
                              model: {
                                value: _vm.WordsReference,
                                callback: function ($$v) {
                                  _vm.WordsReference = $$v
                                },
                                expression: "WordsReference",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.WordsReferenceInput },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "20px 0 0" },
                          attrs: { span: 4.5 },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "source" },
                            [
                              _c("student-level", {
                                on: { studentLevel: _vm.studentLevel },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "source",
                      staticStyle: { padding: "20px 0 0" },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["新增"],
                                  expression: "['新增']",
                                },
                              ],
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addTechniqueBox(1, "")
                                },
                              },
                            },
                            [_vm._v("新增")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["编辑"],
                                  expression: "['编辑']",
                                },
                              ],
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addTechniqueBox(2, "")
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["删除"],
                                  expression: "['删除']",
                                },
                              ],
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.delTechniqueBox },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.seeJudge
              ? _c(
                  "el-main",
                  [
                    [
                      _c(
                        "el-table",
                        {
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableData,
                            fit: "",
                            border: "",
                            id: "customerTable",
                            "header-cell-style": {
                              background: "#F9F9F9",
                              color: "#222222",
                            },
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            key: 1,
                            attrs: { type: "selection", align: "center" },
                          }),
                          _vm._v(" "),
                          _vm.displaySettings.includes("置顶")
                            ? _c("el-table-column", {
                                key: 3,
                                attrs: {
                                  label: "置顶",
                                  align: "center",
                                  width: "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.is_top == 0
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.topOfList(
                                                        "1",
                                                        scope.row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("置顶")]
                                              )
                                            : _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    background: "#ffb400",
                                                    "border-color": "#ffb400",
                                                  },
                                                  attrs: {
                                                    type: "primary",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.topOfList(
                                                        "0",
                                                        scope.row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("取消置顶")]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  285651196
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: 17,
                            attrs: {
                              label: "适用产品",
                              prop: "product_name",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: 12,
                            attrs: {
                              label: "适用学员级别",
                              prop: "customer_level_str",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _vm.displaySettings.includes("问题描述")
                            ? _c("el-table-column", {
                                key: 4,
                                attrs: { label: "问题描述", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.title.length <= 20
                                            ? _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top-start",
                                                    title: "问题描述",
                                                    width: "260",
                                                    trigger: "hover",
                                                    content: scope.row.title,
                                                    disabled: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(scope.row.title)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top-start",
                                                    title: "问题描述",
                                                    width: "260",
                                                    trigger: "hover",
                                                    content: scope.row.title,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.title.substr(
                                                            0,
                                                            20
                                                          ) + "......"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2420263232
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: 18,
                            attrs: { label: "音频", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticStyle: { height: "50px" } },
                                        [
                                          scope.row.audio != undefined
                                            ? _c("i", {
                                                staticClass:
                                                  "iconfont icon-yuyin1",
                                                staticStyle: {
                                                  color: "#0cc464",
                                                  "font-size":
                                                    "28px !important",
                                                  "margin-top": "38px",
                                                  display: "inherit",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.AudioClick(
                                                      scope.row.audio
                                                    )
                                                  },
                                                },
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "iconfont icon-yuyin1",
                                                staticStyle: {
                                                  color: "#ccc",
                                                  "font-size":
                                                    "28px !important",
                                                  "margin-top": "38px",
                                                  display: "inherit",
                                                },
                                              }),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4056296729
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            key: 19,
                            attrs: {
                              label: "视频",
                              prop: "video",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            height: "120px",
                                            "line-height": "120px",
                                          },
                                        },
                                        [
                                          scope.row.video != undefined
                                            ? _c("video", {
                                                ref: "btn",
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "100%",
                                                },
                                                attrs: { src: scope.row.video },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.videoCli(
                                                      scope.row.video
                                                    )
                                                  },
                                                },
                                              })
                                            : _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#ccc",
                                                    "font-size":
                                                      "18px !important",
                                                  },
                                                },
                                                [_vm._v("暂无")]
                                              ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              488989686
                            ),
                          }),
                          _vm._v(" "),
                          _vm.displaySettings.includes("创建人")
                            ? _c(
                                "el-table-column",
                                {
                                  key: 16,
                                  attrs: {
                                    label: "创建人",
                                    prop: "realname",
                                    align: "center",
                                  },
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c("span", [_vm._v("创建人")]),
                                  ]),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.displaySettings.includes("创建时间")
                            ? _c(
                                "el-table-column",
                                {
                                  key: 5,
                                  attrs: {
                                    label: "创建时间",
                                    prop: "create_time",
                                    align: "center",
                                    width: "260",
                                  },
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c("span", [_vm._v("创建时间")]),
                                  ]),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.searchJudge
                            ? _c("el-table-column", {
                                key: 14,
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  width: "180",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              key: 15,
                                              attrs: {
                                                type: "primary",
                                                size: "small",
                                                align: "center",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addTechniqueBox(
                                                    3,
                                                    scope.row.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("话术详情")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3834298876
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          ref: "tableLeft",
                          attrs: { id: "tableLeft" },
                          on: {
                            scroll: function ($event) {
                              return _vm.exterHandleScroll()
                            },
                          },
                        },
                        [
                          _c("div", {
                            staticStyle: { height: "10px" },
                            attrs: { id: "bodyLeft" },
                          }),
                        ]
                      ),
                    ],
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.seeJudge
              ? _c("el-pagination", {
                  staticClass: "pagination",
                  attrs: {
                    "current-page": _vm.page,
                    "page-sizes": [10, 20, 30, 50, 100],
                    "page-size": this.pagesize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                    "update:currentPage": function ($event) {
                      _vm.page = $event
                    },
                    "update:current-page": function ($event) {
                      _vm.page = $event
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "新增话术视频",
                  visible: _vm.dialogPlay,
                  width: "30%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogPlay = $event
                  },
                  close: _vm.closeDialog,
                },
              },
              [
                _c("video", {
                  attrs: {
                    src: _vm.videoUrl,
                    controls: "",
                    autoplay: "",
                    width: "100%",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "audio",
                attrs: {
                  title: "新增话术音频",
                  visible: _vm.dialogAudio,
                  width: "30%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogAudio = $event
                  },
                  close: _vm.closeAudio,
                },
              },
              [
                _c("audio", {
                  attrs: {
                    refs: "audio",
                    width: "100%",
                    src: _vm.AudioUrl,
                    controls: "",
                    autoplay: "",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "addTechnique-dialogs speechReference-dialogs",
                attrs: { title: _vm.titleArt, visible: _vm.addTechnique },
                on: {
                  "update:visible": function ($event) {
                    _vm.addTechnique = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingES,
                        expression: "loadingES",
                      },
                    ],
                    staticClass: "dialog-content",
                  },
                  [
                    _c(
                      "el-form",
                      {
                        staticStyle: { width: "500px", "margin-left": "25px" },
                        attrs: { "label-width": "120px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "问题描述:" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                disabled: _vm.prohibit,
                              },
                              model: {
                                value: _vm.followUpEs.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.followUpEs, "title", $$v)
                                },
                                expression: "followUpEs.title",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "适用学员级别:" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.StudentLevel,
                                size: "small",
                                clearable: "",
                                disabled: _vm.prohibit,
                                props: { expandTrigger: "hover" },
                              },
                              on: { change: _vm.handleChange },
                              model: {
                                value: _vm.followUpEs.level,
                                callback: function ($$v) {
                                  _vm.$set(_vm.followUpEs, "level", $$v)
                                },
                                expression: "followUpEs.level",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "适用产品:" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: _vm.prohibit },
                              model: {
                                value: _vm.followUpEs.products,
                                callback: function ($$v) {
                                  _vm.$set(_vm.followUpEs, "products", $$v)
                                },
                                expression: "followUpEs.products",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "适用范围:" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                placeholder: "请选择部门",
                                options: _vm.bumenoptions,
                                props: {
                                  value: "id",
                                  label: "name",
                                  multiple: true,
                                },
                                size: "small",
                                disabled: _vm.prohibit,
                                "collapse-tags": "",
                                clearable: "",
                              },
                              on: { change: _vm.structureclick },
                              model: {
                                value: _vm.structure_id,
                                callback: function ($$v) {
                                  _vm.structure_id = $$v
                                },
                                expression: "structure_id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "上传音频:" } },
                          [
                            _vm.audioShow
                              ? _c("i", {
                                  staticClass:
                                    "icon-a-8wuyinpinshangchuanwenjian",
                                  staticStyle: {
                                    color: "#ccc",
                                    "font-size": "20px !important",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.audioNum != 3
                              ? _c(
                                  "el-upload",
                                  {
                                    ref: "upload",
                                    staticClass: "upload-demo",
                                    attrs: {
                                      action: "",
                                      accept: ".mp3",
                                      "show-file-list": false,
                                      "http-request": _vm.uploadSectionFile,
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-jia1",
                                      staticStyle: {
                                        color: "#1A95FF",
                                        "font-size": "14px !important",
                                      },
                                    }),
                                    _vm._v(" \n                            "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#1A95FF",
                                          "font-size": "14px",
                                        },
                                      },
                                      [_vm._v("上传音频话术")]
                                    ),
                                    _vm._v(" "),
                                    _c("span"),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.fileList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "margin-bottom": "10px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { overflow: "auto" } },
                                    [
                                      _c("audio", {
                                        ref: "audio",
                                        refInFor: true,
                                        staticStyle: {
                                          float: "left",
                                          "margin-right": "14px",
                                        },
                                        attrs: {
                                          width: "100%",
                                          src: item,
                                          controls: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.fnPlayAudio()
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.prohibitE
                                        ? _c("i", {
                                            staticClass:
                                              "icon-shanchu1 deleteArt",
                                            staticStyle: {
                                              "margin-top": "5px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.deleteAudio(index)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.progressBar
                              ? _c("el-progress", {
                                  attrs: { percentage: _vm.progressPercent },
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "上传视频:" } },
                          [
                            _vm.videoShow
                              ? _c("i", {
                                  staticClass: "icon-wushipin",
                                  staticStyle: {
                                    color: "#ccc",
                                    "font-size": "28px !important",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.videoNum != 3
                              ? _c(
                                  "el-upload",
                                  {
                                    ref: "uploads",
                                    staticClass: "upload-demo",
                                    attrs: {
                                      action: "",
                                      accept: _vm.videoFormat,
                                      "show-file-list": false,
                                      "http-request": _vm.uploadSectionVideo,
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-jia1",
                                      staticStyle: {
                                        color: "#1A95FF",
                                        "font-size": "14px !important",
                                      },
                                    }),
                                    _vm._v(" \n                            "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#1A95FF",
                                          "font-size": "14px",
                                        },
                                      },
                                      [_vm._v("上传视频话术")]
                                    ),
                                    _vm._v(" "),
                                    _c("span"),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.fileListVideo, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "margin-bottom": "10px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { overflow: "auto" } },
                                    [
                                      _c("video", {
                                        ref: "video",
                                        refInFor: true,
                                        staticStyle: {
                                          width: "160px",
                                          height: "160px",
                                          float: "left",
                                          "margin-right": "14px",
                                        },
                                        attrs: { src: item, controls: "" },
                                      }),
                                      _vm._v(" "),
                                      _vm.prohibitE
                                        ? _c("i", {
                                            staticClass:
                                              "icon-shanchu1 deleteArt",
                                            staticStyle: {
                                              "margin-top": "19%",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.deleteVideo(index)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.progressVideoE
                              ? _c("el-progress", {
                                  attrs: { percentage: _vm.progressVideo },
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "话术文档:" } },
                          [
                            _vm.fileShow
                              ? _c("i", {
                                  staticClass: "icon-yulanwendang",
                                  staticStyle: {
                                    color: "#ccc",
                                    "font-size": "20px !important",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.fileNum != 3
                              ? _c(
                                  "el-upload",
                                  {
                                    ref: "upload",
                                    staticClass: "upload-demo",
                                    attrs: {
                                      action: "",
                                      accept: ".pdf, .doc, .docx, .txt",
                                      "show-file-list": false,
                                      "http-request": _vm.uploadSectionFilew,
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-jia1",
                                      staticStyle: {
                                        color: "#1A95FF",
                                        "font-size": "14px !important",
                                      },
                                    }),
                                    _vm._v(" \n                            "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#1A95FF",
                                          "font-size": "14px",
                                        },
                                      },
                                      [_vm._v("上传附件")]
                                    ),
                                    _vm._v(" "),
                                    _c("span"),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.fileListw, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "margin-bottom": "10px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "80%",
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                            "white-space": "nowrap",
                                            color: "rgb(26, 149, 255)",
                                            "font-size": "14px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.file_name))]
                                      ),
                                      _vm._v(" "),
                                      _vm.downloadShow
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                padding: "5px 5px",
                                              },
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: { download: item.url },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.downloadFile(
                                                        item.file_name,
                                                        item.url
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("下载")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.previewShow
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                padding: "5px 5px",
                                              },
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEdit(item)
                                                },
                                              },
                                            },
                                            [_vm._v("预览")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.deleteShow
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                padding: "5px 5px",
                                              },
                                              attrs: {
                                                size: "small",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.deletefile(index)
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.progressBarw
                              ? _c("el-progress", {
                                  attrs: { percentage: _vm.progressPercentw },
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.prohibitE
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "话术参考:" } },
                              _vm._l(_vm.contentlist, function (item, int) {
                                return _c(
                                  "div",
                                  { key: int, staticClass: "technique-item" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        disabled: _vm.prohibit,
                                      },
                                      model: {
                                        value: item.content,
                                        callback: function ($$v) {
                                          _vm.$set(item, "content", $$v)
                                        },
                                        expression: "item.content",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      int == 0
                                        ? _c("span", {
                                            staticClass: "iconfont icon-jia1",
                                            on: { click: _vm.addTechniqueFun },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      int != 0
                                        ? _c("span", {
                                            staticClass: "iconfont icon-jian",
                                            on: {
                                              click: function ($event) {
                                                return _vm.reduceTechniqueFun(
                                                  int,
                                                  item.lid
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c(
                              "el-form-item",
                              { attrs: { label: "话术参考:" } },
                              _vm._l(_vm.contentlist, function (item, lid) {
                                return _c(
                                  "div",
                                  { key: lid, staticClass: "technique" },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.content))]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "zan" }, [
                                      _c("span", {
                                        staticClass: "iconfont icon-dianzan1",
                                        class: {
                                          active: _vm.currentIndex === item.lid,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.thumbsUp(item.lid)
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "zan-num" }, [
                                        _vm._v(_vm._s(item.helpful)),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: {
                          "text-align": "center",
                          "margin-top": "40px",
                        },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.addTechnique = false
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", disabled: _vm.prohibit },
                            on: { click: _vm.addLanguages },
                          },
                          [_vm._v("确认")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }