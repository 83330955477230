import "core-js/modules/es6.regexp.to-string";
// 引入ali-oss
var OSS = require("ali-oss");
var exports = {};
/**
阿里云配置
 *  [accessKeyId] {String}：通过阿里云控制台创建的AccessKey。
 *  [accessKeySecret] {String}：通过阿里云控制台创建的AccessSecret。
 *  [bucket] {String}：通过控制台或PutBucket创建的bucket。
 *  [region] {String}：bucket所在的区域， 默认oss-cn-hangzhou。
 */
export function client() {
  return new OSS({
    region: "oss-cn-beijing",
    //oss-cn-shenzhen.aliyuncs.com
    accessKeyId: "LTAI5tAywsZvAKNuDUnQWixq",
    callbackUrl: 'https://tcrmapi.ternarysystem.com/clientserver.php/AliyunOss/callback',
    accessKeySecret: "rGEnCYCmdILm5R849PqWtyA4aFLQ52",
    bucket: "crm-bu"
  });
}
/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
export var getFileNameUUID = function getFileNameUUID() {
  function rx() {
    return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
  }
  return "".concat(+new Date(), "_").concat(rx()).concat(rx());
};
export default exports;