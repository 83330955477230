import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCustomerLevel as _getCustomerLevel } from '@/api/ceshi_xiugai/whole';
export default {
  name: 'studentLevels',
  data: function data() {
    return {
      StudentLevel: [],
      //学员级别
      Student: [] //学员级别所选中的
    };
  },
  mounted: function mounted() {
    this.getCustomerLevel();
  },
  methods: {
    getCustomerLevel: function getCustomerLevel() {
      var _this = this;
      _getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        for (var item in res.data) {
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              dats.label = dats.name;
              dats.value = dats.id;
            });
          }
        }
        var data = [{
          id: 0,
          label: '适用全部',
          value: 0
        }];
        res.data = data.concat(res.data);
        _this.StudentLevel = res.data;
      });
    },
    handleChange: function handleChange(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 学员级别的最后一位ID
      this.$emit('studentLevel', share);
    }
  }
};